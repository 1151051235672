formly-field {
    .mat-mdc-form-field {
        .mdc-text-field .mdc-line-ripple::before {
            border-bottom-width: 1.5px !important;
        }
        
        // Bottom form field border color
        .mdc-text-field:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
            border-bottom-color: #9090906b !important;
        }

        .mat-mdc-form-field-flex {
            height: 45px;

            .mat-mdc-form-field-infix {
                padding-top: 17px;
                padding-bottom: 0;
            }
        }

        &.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
            transform: translateY(0);

            .mat-mdc-select-arrow {
                top: -1px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label{
            &--float-above{
                top: 19px;
            }

            top: 29px;
        }

        // Subscript wrappers for mat-form-field that are not set to dynamic need to be 20px in height.
        .mat-mdc-form-field-subscript-wrapper:not(.mat-mdc-form-field-subscript-dynamic-size) {
            height: 20px !important;
        }
    }

    &.date-range-picker-field {
        z-index: 100;

        mat-form-field .mat-mdc-text-field-wrapper {
            overflow: visible;
        }
    }
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
    height: 38px;
}

// Floating label color
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--topnav-backgroud-color) !important;
}

.mdc-button__label {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open {
    padding-top: 0;
    mat-option {
        &.mdc-list-item--selected {
            .mdc-list-item__primary-text {
                color: var(--topnav-backgroud-color) !important;
            }
        }
        .mdc-list-item__primary-text {
            text-wrap: nowrap !important;
        }
    
        // Hide mat-select option selected checkbox
        mat-pseudo-checkbox {
            display: none !important;
        }
    }
}

// Remove focus outline for input and textarea
input:focus-visible, textarea:focus-visible {
    outline: none !important;
}
