/*
    CSS units - Rules of thumb.
    font-size: rem
    width: % - used in combination with a max-width, 
           ch - wide the “0” character, used if you need something to fit something like 20 charactes (20ch) 
    height: question yourself "do I really need to set height" if yes -> use a min-height
           what about ag grid? TODO Task 67048
    padding/margin: rem or em, em should be used most often for padding of buttons
    media queries: em / px (to be discussed)
    px: only for little things like shadows, borders etc.
           why use px for shadow? TODO Task 67048
*/

/*
Usually mat components inherit their properties (like font-size) from their parent element. They can be overridden to an explicit size using CSS. 
It's recommend a specificity of at least 1 element + 1 class for selecting mat components.

Example:
mat-form-field.mat-mdc-form-field {
  font-size: 16px;
}
*/

// These are the dafault variables used throught
// They can pe overwritten by setting them again in
// tc-custom-app - _custom-variables.scss (the associated app)

:root {
  // Colors
  --tc-body-background-color: #e5e5e5;
  --tc-text-color: #08192c;
  --tc-table-header-color: #004976;
  --tc-main-background-color: #ffffff;
  --tc-accent-color: #ff7f00;
  --tc-table-row-border: #ffffff;
  --tc-table-row-border-width: 0.2rem;

  --tc-grid-toggle-expand-icon-color: #AC2171;

  --tc-grid-checkbox-indeterminate-color: #AC2171;
  --tc-grid-checkbox-unchecked-color: #786F78;
  --tc-grid-checkbox-checked-color: #AC2171;

  --tc-grid-row-background-color: 'transparent';
  --tc-grid-expanded-row-background-color: #F7F5F5;

  --tc-selections-header-background-color: #f2f2f2;
  --tc-highlight-color: #dff2fa;
  --tc-secondary-color: #ff9e1b;
  --tc-button-accent-color: #22b2f0;
  --tc-smart-button-background-color: #FFFFFF;

  --tc-labels-color: #909090;
  --tc-file-icon-color: #dbdbdb;
  --tc-border-color: #f2f2f2;

  --tc-gradient-start-color: #00253b;
  --tc-gradient-end-color: #004976;

  --tc-menu-item-hover-color: #05588b;

  --tc-login-banner-background-color: #033f61;
  --tc-login-button-background-color: #033f61;
  --tc-login-form-background-color: #d0cecf;
  --tc-login-title-color: #033f61;

  // Notification Colors
  --tc-success-color: #48bf84;
  --tc-warning-color: var(--tc-secondary-color);
  --tc-error-color: #f15946;
  --tc-border-hover-color: #ebebeb;

  // Shadows - doubt the color displayed in the guildlines is #051422
  --tc-main-shadow: 0px 3px 6px #31404629;

  // Typography
  --tc-main-heading-font: bold 1.6rem/1.8rem Arial;
  --tc-table-heading-font: bold 1.4rem/1.6rem Arial;
  --tc-body-font: normal 1.4rem/1.6rem Arial;
  --tc-secondary-heading-font: var(--tc-body-font);
  --tc-input-font: var(--tc-body-font);
  --tc-input-label-font: normal 1.2rem/1.6rem Arial;
  --tc-login-title-font-family: Arial;

  --custom-gray: #5a515a;

  // Topnav
  --topnav-height: 7rem;
  --topnav-backgroud-color: #3d383d;
  --page-title-height: 6.6rem;

  // Sidenav
  --sidenav-width: 24.4rem;
  --sidenav-top-margin: var(--topnav-height);

  // Layouts specifics
  --body-min-width: 128rem;
  --vertical-layout-max-width: var(--body-min-width);
  --tc-login-banner-height: 3.125em;
  --tc-login-container-padding: 0;

  // Fonts  - not sure - TO DO
  --title-font: normal normal bold 2.6967vh/1.3428571429em Open Sans Condensed;

  // Dialog  - not sure - TO DO
  --dialog-title-font: normal normal bold 1.6rem Arial;
  --dialog-title-border-bottom: 0.0625em solid black;
  --dialog-title-color: #FFFFFF;
  --dialog-actions-background: #f2f2f2;
  --tc-dialog-title-background: #3d383d;
  --tc-dialog-title-close-button-color: #FFFFFF;

  // Confirm Dialog
  --tc-confirm-dialog-button-border: 0.25rem solid;
  --tc-confirm-dialog-no-button-border-color: #3d383d;
  --tc-confirm-dialog-yes-button-border-color: #3d383d;
  --tc-confirm-dialog-yes-button-background: #004976;
  --tc-confirm-dialog-yes-button-color: #FFFFFF;
  --tc-confirm-dialog-no-button-color: black;

  // Spinner
  --tc-loading-color: #33a8db;

  // Flex Layout
  --tc-form-flex-gap: 2rem;

  --tc-version-header-background: #3d383d;
  --tc-version-header-color: #ffffff;

  // This class is added to the body when the breakPoint
  // specified in assets/config.json is reached
  body.responsive {
    --sidenav-top-margin: 0;
    --sidenav-width: 32rem;
  }

  --tc-grid-combo-editor-font-size: 1.2rem;
  --tc-grid-combo-editor-options-height: calc(3 * var(--tc-grid-combo-editor-font-size));
}

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
