/*
    This file is imported directly into _material-theme.scss of tcp which is why 
    this file needs to exist even if empty.

    In this file you can overwrite the following:
    $tc-primary-palette
    $tc-primary-default-hue
    $tc-accent-palette
    $tc-accent-default-hue
    $tc-warn-palette
    $tc-warn-default-hue
    $is-dark
    $theme-foreground
    $theme-background

    If you need a default pallete from angular material use:

    @import '@angular/material/theming';
    $tc-warn-palette: $mat-red;
*/