@use '@angular/material' as mat;
@import '@angular/material/theming';

$tc-text-color: #08192c;
/*
  For generating pallets base on a single color use:
  http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5
*/

$tc-primary-palette: (
  50: #e0f0f7,
  100: #b3daea,
  200: #80c1dd,
  300: #4da8cf,
  400: #2695c4,
  500: #0082ba,
  600: #007ab3,
  700: #006fab,
  800: #0065a3,
  900: #005294,
  A100: #bfdeff,
  A200: #8cc4ff,
  A400: #59aaff,
  A700: #409dff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$tc-primary-default-hue: 500;

$tc-accent-pallete: (
  50: #e4f6fd,
  100: #bde8fb,
  200: #91d9f8,
  300: #64c9f5,
  400: #43bef2,
  500: #22b2f0,
  600: #1eabee,
  700: #19a2ec,
  800: #1499e9,
  900: #0c8ae5,
  A100: #ffffff,
  A200: #dcefff,
  A400: #a9d8ff,
  A700: #90ccff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$tc-accent-default-hue: 500;

$tc-warn-pallete: mat.$red-palette;
$tc-warn-default-hue: 600;

// Background palette for light themes.
$theme-background: (
  status-bar: map_get(mat.$grey-palette, 300),
  app-bar:    map_get(mat.$grey-palette, 100),
  background: map_get(mat.$grey-palette, 50),
  hover:      rgba(black, 0.04),
  card:       white,
  dialog:     white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get(mat.$grey-palette, 300),
  selected-disabled-button: map_get(mat.$grey-palette, 400),
  disabled-button-toggle: map_get(mat.$grey-palette, 200),
  unselected-chip: map_get(mat.$grey-palette, 300),
  disabled-list-option: map_get(mat.$grey-palette, 200),
  tooltip: map_get(mat.$grey-palette, 700),
);

$tc-label-color: #909090;

// Foreground palette for light themes.
$theme-foreground: (
  base:              black,              //Good
  divider:           $tc-label-color,      //Set  
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba(black, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  hint-text:         $dark-disabled-text,
  secondary-text:    $tc-label-color,      //Set
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              $tc-text-color,       //Set
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

$is-dark: false;
$font-family: 'Arial';
$body-font-size: 1.6rem;

// We impot this in order to overwrite the default material app 
// Unfortunately there are no if it exists => then import in scss so 
// if you get an error saying that this doesn't exist then you will
// have to create it in the custom app and leave it blank until you
// untill you know what colors will be used
@import 'app/custom/_custom-material-theme.scss';

$primary: mat.define-palette($tc-primary-palette, $tc-primary-default-hue);
$accent : mat.define-palette($tc-accent-pallete, $tc-accent-default-hue);;
$warn : mat.define-palette($tc-warn-pallete, $tc-warn-default-hue);

// Default config except $font-family
$typography-config: mat.define-typography-config(
    $font-family: $font-family,
    $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    $headline-4: mat.define-typography-level(34px, 40px, 400),
    $headline-5: mat.define-typography-level(24px, 32px, 400),
    $headline-6: mat.define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.define-typography-level(16px, 28px, 400),
    $subtitle-2: mat.define-typography-level(14px, 24px, 500),
    $body-1: mat.define-typography-level($body-font-size, 24px, 400),
    $body-2: mat.define-typography-level(14px, 20px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500),
);

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: $is-dark,
    foreground: $theme-foreground,
    background: $theme-background
  ),
  typography: $typography-config,
  density: 0
));

@include mat.all-component-themes($theme);
@include mat.core();
@include mat.all-component-densities(0);