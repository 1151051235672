// Here we can overwritte any of the dafault variables
// used throught the app declared in tcp - _variables.scss

/*
    CSS units - Rules of thumb.
    font-size: rem
    width: % in combination with a max-width, ch
    height: question yourself "do i really need to set height" if yes -> use a min-height
    padding/margin: rem or em, em should be used most often for padding of buttons
    media queries: em / px (to be discussed)
    px: only for little things like shadows, borders etc.
*/
:root {
  // Colors
  --tc-login-banner-background-color: #033f61;
  --tc-login-banner-background-color: #ffffff;
  --tc-login-form-background-color: #ffffff;
  --tc-login-title-color: #786F78;
  
  // Shadows - doubt the color displayed in the guildlines is #051422

  // Login
  --tc-login-banner-height: 0;
  --tc-login-container-padding: 9.375rem;

  // Typography
  --tc-login-title-font-family: Open Sans Condensed;

  // Topnav

  // Sidenav

  // Topnav
  --topnav-height: 8.6rem;
  --topnav-backgroud-color: #ff7f00;

  // Homepage link color
  --homepage-link-color: #ffffff;

  // Light grey background
  --light-gray-background: #f7f5f5;

  // text black
  --text-black: #3d383d;
  --text-grey: #786F78;

  --tc-nav-item-separator-color: #ffffff;

  --tc-highlight-color: #ffffff;

  // Table header background color
  --tc-table-header-color: #786f78;

  // Table row background color
  --tc-selections-header-background-color: #ffffff;

  // Table border-bottom color
  --tc-table-row-border: #cccccc;
  --tc-table-row-border-width: 0.125rem;

  // Table heading font size (12px)
  --tc-table-heading-font: bold 1.2rem/1.4rem Arial;

  --tc-mat-container-background: #f5f5f5;

  --tc-mat-primary-button-color: #786f78;

  --tc-mat-icon-warn: #e53935;

  --tc-table-secondary-header-color: #efefef;

  --tc-dialog-header-background-color: #3d383d;
  --tc-dialog-title-background: #3d383d;
  --tc-dialog-title-close-button-color: #786f78;
  --tc-confirm-dialog-yes-button-background: #ff7f00;
  --tc-confirm-dialog-no-button-border-color: #786f78;
  --tc-confirm-dialog-yes-button-border-color: #ff7f00;
  --tc-confirm-dialog-yes-button-color: #efefef;
  --tc-confirm-dialog-no-button-color: #786f78;

  // Button borders
  --tc-smart-button-border: 0.125em solid var(--tc-mat-primary-button-color);

  // Button colors
  --tc-login-button-background-color: #ff7f00;

  --mat-mdc-button-persistent-ripple-color: #0082ba;
}
