@use 'sass:math';
@import 'ngx-toastr/toastr';
@import './assets/material-icons/material-icons.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '_variables.scss';
@import '_material-theme.scss';
@import '_flex-layout.scss';
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '_material-overrides';

html {
  font-size: 62.5%;
}

//tc spinner
div.tc-spinner.custom-spinner {
  z-index: 99999;
}

// tc spinner overlay
div.tc-spinner-overlay.custom-overlay {
  opacity: 1;
  z-index: 999999;
}

body,
html {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font: normal normal 1.6rem Arial !important;
  background-color: var(--tc-body-background-color);
  min-width: var(--body-min-width);
}

.app-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.app-loading .spinner {
  height: 25rem;
  width: 25rem;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.app-loading .spinner .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: var(--tc-loading-color);
}

.app-loading .logo {
  width: 15.5rem;
  height: 5rem;
  background: url(./app/custom/assets/images/logo.png) center center no-repeat;
  background-size: cover;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -2.1875em;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -7.75em;
  }
}

//mat toolbar
mat-toolbar.tc-layout-toolbar {
  background: var(--topnav-backgroud-color);
  color: #033f61;
  padding: 0;
  box-shadow: 0 0.1875em 0.125em -0.0625em rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  .app-toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;

      .help-button {
        color: white;
        margin: 0 0 0 1em;
        padding: 1rem 0rem;
        min-width: 5rem;
      }
    }
    .center {
      font: var(--tc-main-heading-font);
      flex: 1;
    }
    .right {
      .current-user {
        margin-right: 0.625em;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .menu-button {
    color: var(--tc-accent-color);
    margin: 0 1em;
  }
}

mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 1.6rem Arial !important;
  letter-spacing: normal;
  margin: 0;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: var(--topnav-height) !important;
}

.mat-toolbar {
  font-size: 1em;
}

.mat-mdc-list-base {
  padding-top: 0.5em !important;
  .mat-mdc-list-item {
    font-size: 1em;
  }
}

.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-content,
.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-item-content {
  padding: 0 1em !important;
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-mdc-list-base .mat-mdc-list-option {
  height: 2.8125em !important;
}

.mat-autocomplete-panel {
  max-height: 16em !important;
}

.mat-mdc-option {
  font-size: 1em;
  line-height: 3em !important;
  height: 3em !important;
  padding: 0 1em !important;
}

.mat-mdc-form-field-infix {
  width: 8.25em !important;
}

.mat-slide-toggle {
  height: 1.5em !important;
  line-height: 1.5em !important;
}

.mat-slide-toggle-bar {
  width: 2.25em !important;
  height: 0.875em !important;
  border-radius: 0.5em !important;
}

.mat-slide-toggle-thumb-container {
  width: 1.25em !important;
  height: 1.25em !important;
  top: -0.1875em !important;
}

.mat-slide-toggle-thumb {
  width: 1.25em !important;
  height: 1.25em !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 127, 0, 0.4);
}

.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: rgba(116, 116, 116, 0.4);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff7f00;
}

.mat-checkbox-inner-container {
  height: 1em !important;
  margin-right: 0.5em !important;
  width: 1em !important;
}

.mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 1.25em) !important;
  top: calc(50% - 1.25em) !important;
  height: 2.5em !important;
  width: 2.5em !important;
}

.mat-radio-label-content {
  padding-left: 0.5em !important;
}

.mat-radio-container {
  width: 1.25em !important;
  height: 1.25em !important;
}

.mat-radio-outer-circle {
  width: 1.25em !important;
  height: 1.25em !important;
  border-width: 0.125em !important;
}

.mat-radio-inner-circle {
  width: 1.25em !important;
  height: 1.25em !important;
}

.mat-radio-button .mat-radio-ripple {
  left: calc(50% - 1.25em) !important;
  top: calc(50% - 1.25em) !important;
  height: 2.5em !important;
  width: 2.5em !important;
}

.mat-radio-checked:not(.mat-radio-disabled) {
  .mat-radio-outer-circle {
    border-color: #ff7f00 !important;
  }
  .mat-radio-inner-circle {
    background-color: #ff7f00 !important;
  }
}

.tc-dialog-title {
  .mat-icon {
    width: 1em !important;
    height: 1em !important;
  }
}

// Version
.tc-version {
  position: absolute;
  bottom: 0;
  z-index: 1;
  padding: 0.313em;
  background-color: #fff;
  opacity: 0.33;
  border-top-right-radius: 0.625em;
}

input:-internal-autofill-selected {
  background-color: rgb(232, 240, 254) !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

/*#region MainAppContainter*/
.app-container {
  height: 100%;

  router-outlet + * {
    display: block;
    height: 100%;
  }
}
/*#endregion */

/*#region MatDialog*/
.cdk-overlay-container .mdc-dialog__container .mat-mdc-dialog-surface {
  padding: 0;

  > *:first-child {
    overflow: hidden;
    display: block;
    height: 100%;
    max-height: 100%;

    tc-dialog-content,
    .tc-dialog-container,
    .tc-prompt-dialog-container,
    .tc-help-dialog-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100%;

      $dialog-white-space: 2rem;

      .tc-dialog-header,
      .tc-prompt-dialog-header,
      .tc-help-dialog-header {
        position: relative;
        padding-left: 0.635rem;
        margin: 0;
        border-bottom: 1px solid var(--tc-file-icon-color);
        background: var(--tc-dialog-title-background);
        color: var(--dialog-title-color);

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        flex-wrap: wrap;
        row-gap: 1rem;

        .dialog-title {
          display: flex;
          align-items: center;
          font: var(--tc-main-heading-font);
        }

        .dialog-actions {
          flex: 1;
          justify-content: end;

          .tc-buttons-list-containter {
            row-gap: 1rem;
          }
        }

        .dialog-title,
        .dialog-actions {
          display: flex;
          align-items: center;
          column-gap: 1rem;
        }

        // Icon button
        .dialog-actions tc-smart-button {
          color: var(--tc-dialog-title-close-button-color);
        }
      }

      tc-dialog-footer,
      .tc-prompt-dialog-footer,
      .tc-help-dialog-footer {
        background-color: var(--tc-border-color);
      }

      tc-dialog-footer {
        padding: $dialog-white-space;
      }

      .tc-prompt-dialog-footer,
      .tc-help-dialog-footer {
        padding: math.div($dialog-white-space, 2) $dialog-white-space;
      }

      .tc-dialog-content,
      .tc-prompt-dialog-content,
      .tc-help-dialog-content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 0 $dialog-white-space * 0.5;
        margin: $dialog-white-space $dialog-white-space * 0.5;
      }
    }
  }
}
/*#endregion */

/*#region MainScrollBar*/
/* width */
::-webkit-scrollbar {
  width: 0.4rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: transparent;
  border-radius: 0.625em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 0.625em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}
/*#endregion */

/*#region Buttons*/
button .mat-mdc-button-wrapper .mat-mdc-icon:not([data-mat-icon-type='svg']) {
  height: initial;
  width: initial;
}

.mat-mdc-button,
.mat-mdc-stroked-button,
.mat-mdc-flat-button {
  .mat-mdc-button-wrapper {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
  }
}
/*#endregion */

/*#region Menu item*/
.menu-item-bold {
  font-weight: bold;
}
/*#endregion */

/*#region Range date picker */
.md-drppicker {
  top: 5.5rem !important;
}
/*#endregion */

/*#region Formly inputs classes */
.small-date-picker {
  mat-mdc-form-field {
    max-width: 12ch;
  }
}
/*#endregion */

/*#region Custom Styling for small devices */
.extra-small-device,
.small-device {
  app-layout {
    .left {
      width: initial;
    }
  }

  // Makes sure that the grid has a height because on desktop the grid takes whatever
  // space there is left on the screen, but when we have many filter on mobile there
  // is no more space left => we see no grid
  .app-container router-outlet + * {
    height: initial;
    .page {
      overflow: initial;
      padding: 1rem;

      // TODO: See how to calculte url bar height on mobile
      .grid {
        height: calc(100vh - var(--topnav-height) - 16.5rem);

        .tc-grid-add-button {
          right: 0.75rem;
        }
      }
    }
  }
}

// There rest of the styles in this region might need to be changed
// So if you ever need to change something related to these this might be the place you are looking for.
.small-device {
  app-layout {
    .left {
      .logo {
        height: 4rem !important;
      }
    }
  }
}

.extra-small-device {
  app-layout {
    .left {
      .logo {
        height: 4rem !important;
        margin-left: 1rem !important;
      }
    }
    .center {
      display: none;
    }
  }

  .title-id {
    display: none;
  }

  .tc-dialog-content {
    tc-detail-header {
      .dialog-title {
        flex-wrap: wrap;

        .tc-buttons-list-containter {
          flex-wrap: wrap;
          row-gap: 1rem;
        }
      }
    }
  }

  .dialog-actions {
    .tc-buttons-list-containter {
      flex-wrap: wrap;
    }
  }
}
/*#endregion */

tc-vertical-layout {
  mat-toolbar.tc-layout-toolbar {
    position: relative;
  }

  .mat-mdc-list-base {
    padding-top: 0 !important;
    display: flex;

    tc-list-nav-item {
      position: relative;

      &:not(:first-child) {
        &::before {
          content: '';
          display: block;
          width: 0.2rem;
          height: 100%;
          background: #786f78;
          left: -0.1rem;
          top: 0;
          position: absolute;
        }
      }

      .mat-mdc-list-item {
        font-size: 1em;

        &:not(.active-link) {
          .label {
            transition-duration: 0.5s;
          }
        }

        &:hover {
          .tc-nav-menu-data {
            .label {
              color: var(--tc-accent-color);
            }
          }
        }

        &.active-link {
          .tc-nav-menu-data {
            border-bottom: 0.5rem solid var(--tc-accent-color);

            .label {
              color: var(--tc-accent-color);
            }
          }
        }

        .icon-container {
          display: none;
        }

        .tc-nav-menu-data {
          height: 3.6rem;

          .label {
            text-align: center;
            text-transform: uppercase;
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-content,
  .mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-item-content {
    padding: 0 1em !important;
  }

  .mat-mdc-list-base .mat-mdc-list-item,
  .mat-mdc-list-base .mat-mdc-list-option {
    height: 4.1rem !important;
  }
}

// Ionicons styles
.ionicon {
  stroke: currentcolor;
}

.ionicon-fill-none {
  fill: none;
}

.ionicon-stroke-width {
  stroke-width: 32px;
}
