:root {
  .display-flex {
    display: flex;
  }

  .column {
    flex-direction: column;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .row {
    flex-direction: row;
  }

  // TODO: Not sure about this when combining multiple columns and rows one inside another.
  //       Need to investigate more, but time is of the essence right now.
  // formly-group,
  formly-form {
    .display-flex {
      margin: 0 calc(var(--tc-form-flex-gap) / -2);

      > * {
        padding: 0 calc(var(--tc-form-flex-gap) / 2);
      }
    }
  }

  // These class is added to the body when the breakPoint
  // specified in assets/config.json is reached
  body {
    @for $i from 1 through 12 {
      &.extra-small-device {
        .flex-xs-#{$i} {
          width: calc(100% / (12 / #{$i}) - var(--tc-form-flex-gap));

          &.row-formly-field {
            width: calc(100% / (12 / #{$i}));
          }
        }
      }
    }

    @for $i from 1 through 12 {
      &.small-device {
        .flex-sm-#{$i} {
          width: calc(100% / (12 / #{$i}) - var(--tc-form-flex-gap));

          &.row-formly-field {
            width: calc(100% / (12 / #{$i}));
          }
        }
      }
    }

    @for $i from 1 through 12 {
      &.medium-device {
        .flex-md-#{$i} {
          width: calc(100% / (12 / #{$i}) - var(--tc-form-flex-gap));

          &.row-formly-field {
            width: calc(100% / (12 / #{$i}));
          }
        }
      }
    }

    @for $i from 1 through 12 {
      &.large-device {
        .flex-lg-#{$i} {
          width: calc(100% / (12 / #{$i}) - var(--tc-form-flex-gap));

          &.row-formly-field {
            width: calc(100% / (12 / #{$i}));
          }
        }
      }
    }

    @for $i from 1 through 12 {
      &.extra-large-device {
        .flex-xl-#{$i} {
          width: calc(100% / (12 / #{$i}) - var(--tc-form-flex-gap));

          &.row-formly-field {
            width: calc(100% / (12 / #{$i}));
          }
        }
      }
    }

    @for $i from 1 through 12 {
      &.extra-extra-large-device {
        .flex-xxl-#{$i} {
          width: calc(100% / (12 / #{$i}) - var(--tc-form-flex-gap));

          &.row-formly-field {
            width: calc(100% / (12 / #{$i}));
          }
        }
      }
    }

    @for $i from 1 through 12 {
      .flex-#{$i} {
        width: calc(100% / (12 / #{$i}) - var(--tc-form-flex-gap));
      }
    }
  }
}
